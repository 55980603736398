import Layout from 'components/Layout'
import Resource from 'components/global/Resource'
import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import React from 'react'

export default function Delaware() {
	const gradek = ['Literacy Lab | Grade K', 'Zoology | Grade K | Framework', 'Ecology | Grade K | Framework', 'Entomology | Grade K | Framework']
	const grade1 = ['Literacy Lab | Grade 1', 'Wild & Endangered Animals | Grade 1 | Framework', 'Family Stories | Grade 1 | Framework', 'Plants | Grade 1 | Framework']
	const grade2 = [
		'Literacy Lab | Grade 2',
		'Bugs in Their Ecosystems | Grade 2 | Framework',
		'Animal Stories | Grade 2 | Framework',
		'Jobs in My Community | Grade 2 | Framework'
	]
	const gradekNotebooks = ['Grade K | Unit 2 | Word Study Notebook', 'Grade K | Unit 3 | Word Study Notebook', 'Grade K | Unit 4 | Word Study Notebook']
	const grade1Notebooks = [
		'Grade 1 | Unit 1 | Word Study Notebook',
		'Grade 1 | Unit 2 | Word Study Notebook',
		'Grade 1 | Unit 3 | Word Study Notebook',
		'Grade 1 | Unit 4 | Word Study Notebook'
	]
	return (
		<Layout showHeader={false} showFooter={false}>
			<Section margin="mt-20">
				<SectionTitle title="Frameworks" />
				<Row>
					{gradek.map((name) => {
						return (
							<Col key={name} width="w-1/4">
								<Resource title={name} protected />
							</Col>
						)
					})}
				</Row>
				<Row>
					{grade1.map((name) => {
						return (
							<Col key={name} width="w-1/4">
								<Resource title={name} protected />
							</Col>
						)
					})}
				</Row>
				<Row>
					{grade2.map((name) => {
						return (
							<Col key={name} width="w-1/4">
								<Resource title={name} protected />
							</Col>
						)
					})}
				</Row>
			</Section>
			<Section margin="mt-20">
				<SectionTitle title="Word Study Notebooks" />
				<Row center>
					<Col className="mb-6 text-center">
						<h4>Grade K</h4>
						<ul>
							{gradekNotebooks.map((name) => {
								return (
									<li key={name}>
										<Resource title={name} protectedLink text={name}></Resource>
									</li>
								)
							})}
						</ul>
					</Col>
					<Col className="text-center">
						<h4>Grade 1</h4>
						<ul>
							{grade1Notebooks.map((name) => {
								return (
									<li key={name}>
										<Resource title={name} protectedLink text={name}></Resource>
									</li>
								)
							})}
						</ul>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}
